import theme from '@/style';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import {DrawStatus, DATA_MAP} from '../draw-staus';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Text from '@/components/basic/text';
import WonResult from '../won/won';
import ShareButton from '../bets/share-btn';
import {Svg, Line} from 'react-native-svg';
import Price from '../price';
import {useResponsiveDimensions} from '@/utils';
export interface OrderCardProps {
  hideShare?: boolean;
  result?: React.ReactElement | null;
  // 未中奖 | 已中奖 | 未开奖 | 结算中 | 已结算
  status: 0 | 1 | 2 | 3 | 4;
  id?: string;
  gameName?: string;
  awardAmount?: number;
  payment?: number;
  drawTime?: string;
  betsTime?: string;
  onCopy?: () => void;
  hasWin?: boolean;
  hideDraw?: boolean;
  drawContainerStyle?: StyleProp<ViewStyle>;
  onShare?: () => void;
  hasAward?: boolean;
}

const OrderCard = ({
  hideShare = true,
  id = '',
  gameName = '',
  drawTime,
  onCopy = () => {},
  result = null,
  status = 0,
  payment,
  hideDraw = true,
  awardAmount,
  betsTime,
  hasWin,
  drawContainerStyle = {},
  onShare,
  hasAward = false,
}: OrderCardProps) => {
  const {i18n} = useTranslation();
  const {width} = useResponsiveDimensions();

  return (
    <View style={[theme.margin.lrl, theme.margin.topl]}>
      {status === 0 && hideDraw ? null : (
        <View style={[styles.top, theme.padding.l, drawContainerStyle]}>
          {result}
          <Svg width={width - 40} height={1} style={styles.line}>
            <Line
              x1="0"
              y1="0"
              x2={width - 40}
              y2="0"
              stroke="#9FA5AC"
              strokeWidth="2"
              strokeDasharray={'5,5'}
            />
          </Svg>
        </View>
      )}
      <View style={[styles.bottom, theme.padding.l]}>
        {/* 游戏内部分享按钮 */}
        {((status === 4 && awardAmount && awardAmount > 0) ||
          [1, 2, 3].includes(status)) && (
          <View
            style={[
              theme.flex.row,
              theme.flex.between,
              theme.flex.centerByCol,
              theme.margin.btml,
            ]}>
            <Text
              color={theme.basicColor.dark}
              size="medium"
              fontFamily="fontInterBold">
              Share
            </Text>
            <ShareButton onShare={onShare} hasAward={hasAward} />
          </View>
        )}
        <DrawStatus
          isDetail
          colors={DATA_MAP[status].bg}
          textColor={DATA_MAP[status].color}
          label={i18n.t(`bets-page.status.${DATA_MAP[status].text}`)}
          id={id}
          onCopy={onCopy}
        />
        <View
          style={[
            theme.flex.row,
            theme.flex.between,
            theme.flex.centerByCol,
            theme.margin.topl,
          ]}>
          <View>
            <Text
              color={theme.basicColor.dark}
              size="medium"
              fontFamily="fontInterBold">
              {gameName}
            </Text>
            <View style={[theme.flex.row, theme.flex.centerByCol]}>
              <Text color="#5F6975" style={[theme.margin.rights]}>
                {i18n.t('bets-page.label.drawTime')}
              </Text>
              <Text color={theme.fontColor.second}>{drawTime}</Text>
            </View>
          </View>
          <View style={[theme.flex.alignEnd]}>
            <Text color="#5F6975"> {i18n.t('bets-detail.label.payment')}</Text>
            {/* <Text
              color={theme.basicColor.dark}
              size="medium"
              fontFamily="fontDinBold">
              {toPriceStr(payment)}
            </Text> */}
            <Price
              price={payment}
              textProps={{size: 'medium', color: theme.basicColor.dark}}
            />
          </View>
        </View>
        {(status === 1 || (status === 4 && hasWin)) && (
          <WonResult awardAmount={awardAmount} />
        )}
        {(status === 0 || (status === 4 && !hasWin)) && (
          <View style={[theme.padding.tbs]}>
            <Text textAlign="center" fontSize={15} primary>
              {i18n.t('game-page.label.guessWrong')}
            </Text>
          </View>
        )}
        {status === 3 && (
          <View style={[theme.padding.tbs]}>
            <Text textAlign="center" fontSize={15} primary>
              {i18n.t('game-page.label.ticketSetting')}
            </Text>
          </View>
        )}
        {!hideShare && (
          <View
            style={[
              theme.flex.row,
              theme.flex.between,
              theme.flex.centerByCol,
              theme.margin.topl,
            ]}>
            <Text color={'#5F6975'}>Share</Text>
            <ShareButton hasAward={false} />
          </View>
        )}
      </View>
      <View
        style={[
          theme.background.white,
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
          theme.padding.l,
        ]}>
        <Text
          color={theme.basicColor.dark}
          size="medium"
          fontFamily="fontInterBold">
          {i18n.t('game-page.label.myBets')}
        </Text>
        <Text color={'#5F6975'}>
          {i18n.t('game-page.label.betTime')}：{betsTime}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  top: {
    backgroundColor: theme.basicColor.white,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  bottom: {
    backgroundColor: theme.basicColor.white,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomColor: theme.backgroundColor.grey,
    borderBottomWidth: 1,
  },
  line: {
    position: 'absolute',
    bottom: 1,
    left: 8,
  },
});

export default React.memo(OrderCard);
