import React, {useEffect, useRef} from 'react';
import CustomModal from '@/components/basic/custom-modal';
import {useTranslation} from 'react-i18next';
import {View, Image} from 'react-native';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@/style';
import {StyleProp, ImageStyle} from 'react-native';
import Text from '@basicComponents/text';
import Button from '@/components/basic/button';
import {toPriceStr, getImgs} from '@/utils';
import Drawer, {DrawerRef} from '@/components/basic/game-drawer/game-drawer';
import SharePanel from '@/components/business/share-panel/share-panel';
import {shareToThird, shareToChat} from '@/common-pages/bets/bets.service';
import {IShareInfo} from '@/common-pages/bets/bets.service';
import {useShareToast} from '@/common-pages/hooks/shareToast.hooks';
import globalStore from '@/services/global.state';
import {SafeAny} from '@/types';

interface IPaidShareModal {
  popVisible: boolean;
  setPopVisible: (visible: boolean) => void;
  shareAward: number;
  sharInfo: IShareInfo;
}

interface IHeadIcon {
  style: StyleProp<ImageStyle> | StyleProp<ImageStyle>[];
}

interface IBodyContent {
  gap: number;
  title: string;
  content: string;
}

interface IFooter {
  gap: number;
  shareAward: number;
  btnStyle: StyleProp<ImageStyle> | StyleProp<ImageStyle>[];
  sure: Function;
  cancel: Function;
}

const CAP = 'capitalize';
const CENTER = 'center';

const HeadIcon = ({style}: IHeadIcon) => (
  <View style={[theme.flex.flex, theme.flex.center]}>
    <Image style={style} source={require('@/assets/icons/icon_success.png')} />
  </View>
);

const BodyContent = ({gap, title, content}: IBodyContent) => (
  <View
    style={[
      theme.flex.flex,
      theme.flex.col,
      theme.flex.centerByCol,
      {
        gap,
      },
    ]}>
    <Text
      calc
      style={[{textTransform: CAP}]}
      color={theme.fontColor.main}
      fontSize={20}
      fontWeight="700">
      {title}
    </Text>
    <Text
      calc
      style={[{textTransform: CAP, textAlign: CENTER}]}
      second
      fontSize={14}
      fontWeight="500">
      {content}
    </Text>
  </View>
);

const Footer = ({gap, shareAward, btnStyle, sure, cancel}: IFooter) => (
  <View
    style={[
      theme.flex.flex,
      theme.flex.row,
      theme.flex.center,
      {
        gap,
      },
    ]}>
    <Button buttonStyle={btnStyle} onPress={() => sure()}>
      <View style={[theme.flex.flex, theme.flex.col, theme.flex.center]}>
        <Text
          fontSize={theme.fontSize.m}
          fontWeight="700"
          color={theme.basicColor.primary}>
          Chatroom Share
        </Text>
        {!!shareAward && (
          <Text
            fontSize={theme.fontSize.m}
            fontWeight="700"
            color={theme.backgroundColor.second}>
            {`+ ${toPriceStr(shareAward, {
              spacing: false,
              fixed: 0,
              showCurrency: true,
              needToken: false,
            })}`}
          </Text>
        )}
      </View>
    </Button>
    <Button buttonStyle={btnStyle} onPress={() => cancel()}>
      <Text
        fontSize={theme.fontSize.m}
        fontWeight="700"
        color={theme.basicColor.primary}>
        Continue betting
      </Text>
    </Button>
  </View>
);

const PaidShareModal = ({
  popVisible,
  setPopVisible,
  shareAward,
  sharInfo,
}: IPaidShareModal) => {
  const {shareSuccess, shareFail} = useShareToast();
  const {i18n} = useTranslation();
  const {calcActualSize} = useScreenSize();
  const panelRef = useRef<DrawerRef>(null);
  const hasEnter = useRef<boolean>(false);
  useEffect(() => {
    getImgs((res: SafeAny[]) => {
      if (res && res.length) {
        for (const item of res) {
          if (item.moduleType === 'IM_APP') {
            hasEnter.current = true;
            return;
          }
        }
      }
      globalStore.imIcon = undefined;
    });
  }, []);

  return (
    <>
      <CustomModal
        setPopVisible={setPopVisible}
        popVisible={hasEnter.current && popVisible}
        comps={[
          <HeadIcon
            style={[
              {
                width: calcActualSize(80),
                height: calcActualSize(78),
              },
            ]}
          />,
          <BodyContent
            gap={calcActualSize(12)}
            title={i18n.t('paidSuccess.label.subTitle')}
            content={i18n.t('paidSuccess.label.content')}
          />,
          <Footer
            gap={calcActualSize(12)}
            shareAward={shareAward}
            btnStyle={{
              height: calcActualSize(40),
              padding: theme.paddingSize.zorro,
              borderWidth: calcActualSize(1),
              borderColor: theme.basicColor.primary,
              backgroundColor: theme.basicColor.white,
            }}
            sure={() => {
              panelRef.current?.open();
              setPopVisible(false);
            }}
            cancel={() => {
              setPopVisible(false);
            }}
          />,
        ]}
      />
      <Drawer mode="bottom" ref={panelRef} contentBackgroundColor="transparent">
        <SharePanel
          gameType={sharInfo.gameCode}
          hasReward={Boolean(shareAward)}
          onClose={() => panelRef.current?.close()}
          onItemPress={pt => {
            panelRef.current?.close();
            shareToThird(
              pt,
              sharInfo,
              (info: IShareInfo) => shareToChat(info),
              () => shareSuccess(shareAward),
              shareFail,
            );
          }}
        />
      </Drawer>
    </>
  );
};

export default PaidShareModal;
